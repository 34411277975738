<template>
  <div>
    <div class="dao2" >
      <!--<router-link :to="{name:''}" v-for="item in data" :key="item.id"><span> {{item.title}}</span></router-link>-->
      <!--<router-link :to="{name:'meng'}"-->
      <!--&gt;-->
        <div class="one"><a   href="#/zou/d/meng"
                              :class="{lala: lala == 'meng' }"
                              @click="lala = 'meng'">梦想伟嘉</a></div>
      <!--</router-link>-->
      <!--<router-link :to="{name:'qi'}">-->
        <div class="one"><a href="#/zou/d/qi"
                            :class="{lala: lala == 'qi' }"
                            @click="lala = 'qi'">企业宣传片</a></div>
      <!--</router-link>-->
      <!--<router-link :to="{name:'hui'}">-->
        <div class="one"><a  href="#/zou/d/hui"
                             :class="{lala: lala == 'hui' }"
                             @click="lala = 'hui'">徽标文化</a></div>
      <!--</router-link>-->
      <!--<router-link :to="{name:'si'}">-->
        <div class="one"><a href="#/zou/d/si"
                            :class="{lala: lala == 'si' }"
                            @click="lala = 'si'">司旗文化</a></div>
      <!--</router-link>-->
      <!--<router-link :to="{name:'li'}">-->
        <div class="one"><a href="#/zou/d/li"
                            :class="{lala: lala == 'li' }"
                            @click="lala = 'li'">理念文化</a></div>
      <!--</router-link>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: "Index",
    data(){
      return {
        data: [],
        lala: "",
      };
    },
  }
</script>

<style lang="less" scoped>
  .lala{
    color: red !important;
  }
  .dao2{
    height:10.5937vw;
    margin-left: 23vw;
    display: flex;
  }
  .one{
    width: 8.3333vw;
    height: 2.9167vw;
    line-height:2.9167vw ;
    border: 0.0521vw solid #a2a2a2;
    border-radius: 1.0417vw;
    margin-right: 2.7083vw;
    margin-top: 2.0313vw;
    text-align: center;
    /* padding-top:1.2vw ; */
  }
  a{
    
    width: 8.3333vw;
    height: 2.9167vw;
    font-size: 1.25vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
  }

  .one:hover{
    color: white;
    background-color: red;
    a{
      color: white;
    }
    /* border-bottom: 0.15vw solid red; */
  }
</style>