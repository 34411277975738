<template>
  <div class="container">
    <div class="div1">企业文化</div>
    <div class="div2">Corporate Culture</div>

     <index></index>
    <router-view></router-view>

  </div>
</template>


<script>
  import Index from './culture/Index'
  export default {
    name: "D",
    data(){
      return{
        data:[]
      }
    },
    components:{
      Index
    }
  }
</script>


<style scoped>
  .container{
    /*height: 52.8646vw;*/
  }
  .div1{
    height: 2.0833vw;
    font-size: 2.2917vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #d7000f;
    margin-top: 4.1146vw;
    text-align: center;
    letter-spacing:0.15vw;
  }
  .div2{
    height: 1.0417vw;
    font-size: 1.3542vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: left;
    color: #646464;
    margin-top: 1.0417vw;
    text-align: center;
  }

</style>